import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-20 my-10" }
const _hoisted_4 = { class: "text-center px-4" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "fs-2x fw-bolder mb-10" }, "Welcome!", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray-400 fs-4 fw-bold mb-10" }, [
          _createTextVNode(" There are no subscriptions added yet."),
          _createElementVNode("br"),
          _createTextVNode(" Kickstart your business by adding a your first subscription ")
        ], -1)),
        _createVNode(_component_router_link, {
          to: "/subscriptions/add-subscription",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Add Subscription")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          class: "mw-100 mh-300px",
          alt: "",
          src: _ctx.getIllustrationsPath('5.png')
        }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}